<template>
  <UiPopup v-model="modelValue!" hide-close-button close-on-click-outside class="pb-3">
    <span class="text-subhead-1 fixed inset-x-0 top-[calc(50%-75px)] mx-auto text-white">
      Don't forget to change stage
    </span>
    <UiTagPrimary
      id="stage"
      :is-menu-open="false"
      left-icon="operations-forward"
      class="mb-2 !h-8 w-full !justify-center"
    >
      <span class="text-caption-4">
        {{ stage.name }}
      </span>
    </UiTagPrimary>
  </UiPopup>
</template>

<script setup lang="ts">
import type { Stage } from '@/types'

const modelValue = defineModel<boolean>()

defineEmits(['update:modelValue'])

type Props = {
  stage: Stage
}

defineProps<Props>()

onNuxtReady(() => {
  setTimeout(() => {
    modelValue.value = false
  }, 2000)
})
</script>

<style scoped></style>
